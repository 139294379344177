import * as React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import {
    DescriptionOutlined,
    DiningOutlined,
    FastfoodOutlined,
    HomeOutlined,
    MapOutlined, TourOutlined,
    TvRounded
} from '@mui/icons-material';
import {useDispatch, useSelector} from 'react-redux';
import {setMobileOpen} from './redux/reducers/navSlice';
import Constant from './Constants';
import {IconButton, Paper, styled, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const drawerWidth = 240;

function FoodNav(props) {
    const {window} = props;

    const nav = useSelector(state => state.nav);
    const user = useSelector(state => state.auth.user);

    const dispatch = useDispatch();
    const theme = useTheme();

    const handleDrawerToggle = () => {
        dispatch(setMobileOpen(false))
    };


    const DrawerHeader = styled('div')(({theme}) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        backgroundColor: Constant.appBarColor,
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    }));


    const drawer = (
        <Box sx={{backgroundColor: Constant.appBarColor, height: '100%'}}>
            <Toolbar/>
            <Divider/>
            <List>
                <ListItem key='home' onClick={() => handleDrawerToggle()} component={Link} to={"/amarillo-food-list"}>
                    <ListItemIcon>{< HomeOutlined sx={{color: 'white'}}/>}</ListItemIcon>
                    <ListItemText sx={{color: 'white'}}>Home</ListItemText>
                </ListItem>
                <ListItem key='foodmap' onClick={() => handleDrawerToggle()} component={Link}
                          to={"/amarillo-food-map"}>
                    <ListItemIcon>{< MapOutlined sx={{color: 'white'}}/>}</ListItemIcon>
                    <ListItemText sx={{color: 'white'}}>Map</ListItemText>
                </ListItem>
                <ListItem key='foodtour' onClick={() => handleDrawerToggle()} component={Link}
                          to={"/amarillo-food-tour"}>
                    <ListItemIcon>{< TourOutlined sx={{color: 'white'}}/>}</ListItemIcon>
                    <ListItemText sx={{color: 'white'}}>Food Tour</ListItemText>
                </ListItem>
                <ListItem key='about' onClick={() => handleDrawerToggle()} component={Link}
                          to={"/about-amarillo-food-app"}>
                    <ListItemIcon>{< DescriptionOutlined sx={{color: 'white'}}/>}</ListItemIcon>
                    <ListItemText sx={{color: 'white'}}>About</ListItemText>
                </ListItem>
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    function handleDrawerClose() {
        dispatch(setMobileOpen(false))
    }

    return (
        <Box
            component="nav"
            sx={{
                width: {sm: drawerWidth}, flexShrink: {sm: 0},
            }}
            aria-label="mailbox folders"
        >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
                container={container}
                variant="temporary"
                open={nav.mobileOpen}
                onClose={handleDrawerToggle}
                // ModalProps={{
                //     keepMounted: true, // Better open performance on mobile.
                // }}
                sx={{
                    display: {xs: 'block', sm: 'none'},
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},

                }}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose} sx={{color: 'white'}}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                    </IconButton>
                </DrawerHeader>
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: {xs: 'none', sm: 'block'},
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                }}
                open
            >
                {drawer}
            </Drawer>
        </Box>
    );
}

FoodNav.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default FoodNav;
