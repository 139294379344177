import {
    Accordion, AccordionDetails, AccordionSummary,
    Box, Button,
    Card, CardActions,
    CardContent, CardHeader,
    Checkbox,
    FormControl, Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Typography,
    useMediaQuery
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {
    getPlaces,
    getPlacesOnTheStrip,
    resetTour,
    setGeneratedPlaces,
    setPlacesOnTour
} from './redux/reducers/placesSlice';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {appBarColor, backgroundColor} from './Constants';
import SEO from './SEO';
import {useTheme} from '@mui/material/styles';
import ListItemText from '@mui/material/ListItemText';
import {CreateOutlined, ExpandMore, LockReset, MapOutlined} from '@mui/icons-material';
import {getCategories, setTourCategories} from './redux/reducers/categorySlice';
import Divider from '@mui/material/Divider';
import PlaceCardAction from './PlaceCardAction';
import * as Constants from './Constants';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const FoodTour = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [markers, setMarkers] = useState([]);
    const [isApple, setIsApple] = useState(false);
    const [map, setMap] = useState(null);
    const params = useParams();
    let placesOnTour = useSelector(state => state.places.placesOnTour);
    const categories = useSelector(state => state.categories.categories);
    const selectedCategories = useSelector(state => state.categories.tourCategories);
    const generatedPlaces = useSelector(state => state.places.generatedPlaces);
    const [groupedPlaces, setGroupedPlaces] = useState({});

    let google;
    let drawingManager;

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('lg'));


    useEffect(() => {
        const isAppleDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);
        setIsApple(isAppleDevice);

        if (map != null && placesOnTour.length > 0) {
            setupMarkers()
        }


        if (Object.keys(groupedPlaces).length == 0) {

            const map = {};

            placesOnTour.forEach((place) => {
                place.place_category.forEach((category) => {
                    const categoryName = category.category_id.name;
                    if (!map[categoryName]) {
                        map[categoryName] = [];
                    }
                    map[categoryName].push(place);
                });
            });

            setGroupedPlaces(map);
        }

    }, [placesOnTour]);


    useEffect(() => {
        setupMap()
        initData()

        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        initMapData()

    }, [map]);

    const initMapData = async () => {
        if (map && placesOnTour.length > 0) {
            await setupMarkers()
        }
    }

    const initData = async () => {
        if (placesOnTour == null || placesOnTour.length === 0) {
            await dispatch(getPlaces())
        }
        if (categories.length === 0) {
            dispatch(getCategories());
        }
    }

    const setupMap = async () => {
        google = window.google
        const {Map} = await google.maps.importLibrary("maps");

        setMap(new Map(document.getElementById("map"), {
                center: {lat: 35.222, lng: -101.8313},
                zoom: 11.9,
            })
        )
    }

    const clearMarkers = async () => {
        markers.forEach((marker) => {
            marker.setMap(null)
            // marker = null
        })
        setMarkers([])
    }

    const handleChange = (event) => {
        const {
            target: {value},
        } = event;
        dispatch(
            setTourCategories(
                typeof value === 'string' ? value.split(',') : value,
            )
        )

        // setSelectedCategories(
        //     // On autofill we get a stringified value.
        //     typeof value === 'string' ? value.split(',') : value,
        // );
    };

    // const getSelectedCategories = () => {
    //     return selectedCategories;
    // };

    const setupMarkers = async () => {
        if (map == null) {
            await setupMap();
            return;
        }

        await clearMarkers()

        const isAppleDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);

        google = window.google
        let currentMarkers = [];
        placesOnTour.forEach((place) => {
            const position = {lat: +place.lat, lng: +place.lng};

            const marker = new google.maps.Marker({
                map: map,
                position: position,
                title: place.name,
                color: 'black',
                label: place.rating === '' ? null : {
                    color: '#ffffff',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    text: place.rating
                },
                // label: { color: '#00aaff', fontWeight: 'bold', fontSize: '14px', text: 'Your text here' },
            });


            let directionsLink = `https://www.google.com/maps/dir/?api=1&destination=${place.lat},${place.lng}`;
            if (isAppleDevice) {
                directionsLink = `maps://?q=${place.lat},${place.lng}`;
            }

            const phone = place.phone;
            const callHref = `tel:${phone}`;

            const name = place.name;
            const address = place.address;
            const tiktokUrl = `${place.video_id}`;
            const detailUrl = `https://amarillofood.app/place/${params.id}`;
            const shareHref = `mailto:?subject=let's eat at this restaurant&body=Hi, Amarillo reviewed this restaurant! ${detailUrl}`;

            let categories = `<div></div>`;
            if (place.place_category)
                categories = `<i>${place.place_category.map((value) => value.category_id.name).join(", ")}</i>`;

            let callDiv = `<div></div>`
            if (place.phone !== '') {
                callDiv = `<hr>
         <div>
                 <a href="${callHref}" target="_blank"/>Call</a>
        </div>`;
            }

            let orderUrl = `<div></div>`
            if (place.order_url !== '') {
                orderUrl = `<hr>
         <div>
                <a href="${place.order_url}" target="_blank"/>Order</a>
        </div>`;
            }

            let websiteUrl = `<div></div>`
            if (place.website_url !== '') {
                websiteUrl = `<hr>
         <div>
                <a href="${place.website_url}" target="_blank"/>Website</a>
        </div>`;
            }


            let dir = `<div></div>`
            if (place.lat !== '') {
                dir = `<hr>
         <div>
                <a href="${directionsLink}" target="_blank"/>Directions</a>
        </div>`;
            }
            const information = new google.maps.InfoWindow({
                content: `
                         <h4>${place.name}</h4>
                             ${categories}
                         <hr>
                         <div>
                             ${address}
                         </div>
                         ${dir}
                         ${callDiv}
                         <hr>
                         <div>
                             <a href="${shareHref}" target="_blank"/>Share</a>
                         </div>
                         <hr>
                          <div>
                             <a href="${tiktokUrl}" target="_blank"/>Watch Review</a>
                         </div>
                         ${orderUrl}
                         ${websiteUrl}
                         `
            });

            marker.addListener('click', function () {
                information.open(map, marker);
            });
            currentMarkers.push(marker)
        });

        setMarkers(currentMarkers)

    }


    const generateTour = () => {
        const places = getPlacesBySelectedCategories();
        dispatch(setGeneratedPlaces(places));
        dispatch(setPlacesOnTour(places))
    }

    const resetCurrentTour = () => {
        dispatch(resetTour())
        dispatch(
            setTourCategories([])
        )
        dispatch(setGeneratedPlaces([]))
    }

    const getPlacesBySelectedCategories = () => {
        let filteredPlaces = [];
        const usedPlaceIds = new Set();

        selectedCategories.forEach((category) => {
            if (groupedPlaces[category]) {
                // Filter out the places that have already been used
                let unusedPlaces = groupedPlaces[category].filter((place) => !usedPlaceIds.has(place.id));

                // If there are no unused places, reset the usedPlaceIds set and filter again
                if (unusedPlaces.length === 0) {
                    usedPlaceIds.clear();
                    unusedPlaces = groupedPlaces[category].filter((place) => !usedPlaceIds.has(place.id));
                }

                // Select a random place from the unused places
                if (unusedPlaces.length > 0) {
                    const randomIndex = Math.floor(Math.random() * unusedPlaces.length);
                    const selectedPlace = unusedPlaces[randomIndex];
                    filteredPlaces.push(selectedPlace);
                    usedPlaceIds.add(selectedPlace.id);
                }
            }
        });

        return filteredPlaces;
    };

    const renderPlaceCard = (place) => {
        return (
            <Grid item xs={12} sm={6} md={4} key={place.id}>
                <Card>
                    <CardContent>
                        <Typography
                            sx={{fontSize: 14, width: '100%', textAlign: 'center'}}
                            color={appBarColor}
                        >
                            <h3>{place.name !== undefined ? place.name : ''}</h3>
                        </Typography>
                        {place.logo_url ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <img width="100px" height="100px" src={place.logo_url}/>
                            </Box>
                        ) : null}
                        {place.rating === '' ? null : (
                            <Typography
                                sx={{fontSize: 13, width: '100%', textAlign: 'center'}}
                                color={appBarColor}
                                gutterBottom
                            >
                                <p>
                                    <strong>Highest Rating: </strong>
                                    {place.rating}
                                </p>
                            </Typography>
                        )}
                        <Typography
                            sx={{fontSize: 13, width: '100%', textAlign: 'center'}}
                            color={appBarColor}
                            gutterBottom
                        >
                            <div>
                                {place.place_category !== undefined
                                    ? place.place_category
                                        .map((value) => value.category_id.name)
                                        .join(', ')
                                    : ''}
                            </div>
                        </Typography>
                        <Typography
                            sx={{fontSize: 14, fontStyle: 'italic'}}
                            color="black"
                        >
                            <p>{place.description}</p>
                        </Typography>
                    </CardContent>
                    <Divider sx={{width: '100%'}}></Divider>
                    <CardActions>
                        <PlaceCardAction
                            index={place.slug}
                            place={place}
                            // saveToList={() => saveToList(place)}
                            isApple={isApple}
                        />
                    </CardActions>
                </Card>
            </Grid>
        );
    };

    return <Box
        sx={{
            backgroundColor: backgroundColor,
            overflowX: 'hidden',
            minHeight: '100vh',
            margin: '10px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}
    >
        <SEO
            title={`Amarillo LA Food Tour`}
            description={`Create your own custom Amarillo Food tour in Los Angeles, CA!`}
            name={`Amarillo food tour`}
            type={`article`}/>

        <Card sx={{
            maxWidth: 500,
            width: '100%',
            marginTop: "10px",
            marginBottom: "10px",
            display: "flex",
            flexDirection: "column",
        }}>

            <CardContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Typography gutterBottom variant="h5" component="div" sx={{padding: '5px', textAlign: 'center'}}>
                    Amarillo Food Tour
                < /Typography>
                <Typography variant="body2" color="black"
                            sx={{padding: '5px', marginBottom: '10px', textAlign: 'center'}}>
                    Create your own custom food tour for <strong><i>Amarillo, Tx </i></strong>!
                </Typography>
                <Typography variant="body2" color="black"
                            sx={{padding: '2px', marginBottom: '5px', textAlign: 'center'}}>
                    Choose different categories and you will get a random place
                    from each category. This will give you a list and also update the map with the list.
                </Typography>
                <Typography variant="body2" color="black"
                            sx={{padding: '5px', marginBottom: '5px', textAlign: 'center'}}>
                    You can also click the create food tour button to get a new random tour.
                </Typography>
                <Typography variant="body2" color="black"
                            sx={{padding: '5px', marginBottom: '5px', textAlign: 'center'}}>
                    This also doesn't currently
                    take in consideration of places locally. You can click the create button again to replace that
                    place.
                </Typography>
                <FormControl sx={{width: '100%'}}>
                    <InputLabel id="demo-multiple-checkbox-label">Category</InputLabel>
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={selectedCategories}
                        onChange={handleChange}
                        input={<OutlinedInput label="Category"/>}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                    >
                        {categories.map((category) => (
                            <MenuItem key={category.id} value={category.name}>
                                <Checkbox checked={selectedCategories.indexOf(category.name) > -1}/>
                                <ListItemText primary={category.name}/>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button variant="contained"
                        sx={{backgroundColor: "#222E50", padding: '5px', flexBasis: '45%', marginTop: '10px'}}
                        id={"generate-places"}
                        aria-label="map"
                        onClick={generateTour}
                >
                    {/*<CreateOutlined/>*/}
                    Create Food Tour
                </Button>
                <Button variant="contained"
                        sx={{backgroundColor: "#222E50", padding: '5px', flexBasis: '45%', marginTop: '10px'}}
                        id={"reset-button"}
                        aria-label="reset tour"
                        onClick={resetCurrentTour}
                >
                    {/*<LockReset/>*/}
                    Reset
                </Button>
            </CardContent>
        </Card>
        <Grid container spacing={2} sx={{marginTop: '10px'}}>
            {generatedPlaces.map((place) => renderPlaceCard(place))}
        </Grid>
        <Box
            sx={{
                height: '100vh',
                width: '100%',
                marginTop: '10px',
            }}

            id="map"
        >

        </Box>
    </Box>

}

export default FoodTour
