import './App.css';
import React, {useEffect, useState} from "react";
import {useTheme} from '@mui/material/styles';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent, Checkbox,
    FormControl, FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    useMediaQuery
} from "@mui/material";
import {appBarColor, backgroundColor} from "./Constants";
import {
    getPlaces,
    resetPlacesToUse,
    setCurrentPlace,
    setOnStrip,
    updateFilteredCategories
} from './redux/reducers/placesSlice';
import {useDispatch, useSelector} from 'react-redux';
import {getCategories, setSelectedCategory} from './redux/reducers/categorySlice';
import {MapOutlined} from '@mui/icons-material';
import {Link, useNavigate, useParams} from 'react-router-dom';
import LoadingAnimation from './LoadingAnimation';
import AddToFoodListDialog from './AddToFoodListDialog';
import {getUserLists, signInWithGoogle} from './redux/reducers/authSlice';
import AlertDialog from './AlertDialog';
import PlaceCardAction from './PlaceCardAction';
import Divider from '@mui/material/Divider';
import SEO from './SEO';
import * as Constants from './Constants';


function Places() {
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertRemoveOpen, setAlertRemoveOpen] = useState(false);
    const [isApple, setIsApple] = useState(false);
    const onStrip = useSelector(state => state.places.onStrip);

    const theme = useTheme();
    const params = useParams();
    const matches = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const categories = useSelector(state => state.categories.categories);
    const foodList = useSelector(state => state.auth.foodLists);
    const user = useSelector(state => state.auth.user);
    const places = useSelector(state => state.places.places);
    const placesMap = useSelector(state => state.places.placesMap);
    const placesToUse = useSelector(state => state.places.placesToUse);
    const selectedCategory = useSelector(state => state.categories.selectedCategory);


    useEffect(() => {
        if (places.length === 0) {
            dispatch(getPlaces())
        }

        if (categories.length === 0) {
            dispatch(getCategories());
        }

        // Check if the user is on an Apple device
        const isAppleDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);
        setIsApple(isAppleDevice);
    }, []);


    useEffect(() => {

        if (user) {
            dispatch(getUserLists());
        }

        // Check if the user is on an Apple device
        const isAppleDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);
        setIsApple(isAppleDevice);
    }, [user]);

    const handleChange = async (event) => {
        await dispatch(setSelectedCategory(event.target.value))
        if (event.target.value === 'All') {
            await dispatch(resetPlacesToUse())
        } else {
            await dispatch(updateFilteredCategories(event.target.value, places, onStrip))
        }
    };

    const handleCheckChange = async (event) => {
        const checked = event.target.checked;
        await dispatch(setOnStrip(checked))
        await dispatch(updateFilteredCategories(selectedCategory, places, checked))
    };

    function saveToList(place) {
        if (user) {
            setData(place)
            setOpen(true)
        } else {
            setAlertOpen(true)
        }

    }

    const handleListOpen = (open) => {
        setOpen(open)
    }

    const handleAlertOpen = (didAccept) => {
        setAlertOpen(false)
        if (didAccept) {
            dispatch(signInWithGoogle())
        }
    }

    const handleAlertRemoveOpen = (didAccept) => {
        setAlertRemoveOpen(false)
        if (didAccept) {
            // dispatch(signInWithGoogle())
        }
    }

    const openPlaceDetails = (place) => {
        dispatch(setCurrentPlace(place))
        navigate(`/place/${place['slug']}`)
    }

    const categoryContainsFoodTruck = (place) => {
        const categoryList = place.place_category;

        if (!categoryList || categoryList.length === 0) return false

        for(let i = 0; i < categoryList.length; i++) {
            const category = categoryList[i];
            const name = category.category_id.name;
            if (name.toLowerCase() === 'food truck') {
                return true;
            }
        }
    }

    return (
        <Box
            sx={{
                backgroundColor: backgroundColor,
                overflowX: 'hidden',
                minHeight: '100vh',
                marginRight: matches ? '10px' : '250px',
                marginLeft: matches ? '10px' : '250px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >

            <SEO
                title={`Amarillo Food Review List`}
                description={`List of Amarillo food reviews. Take a Amarillo food tour`}
                name={`Amarillo Food Review List`}
                type={`article`}/>

            {open ?
                <AddToFoodListDialog data={data} open={open} handleClickOpen={handleListOpen}></AddToFoodListDialog> :
                null}

            {alertOpen ?
                <AlertDialog open={alertOpen} title="Save place"
                             message="Saving a place to a list requires creating an account. Would you like to sign up/login?"
                             handleClickOpen={handleAlertOpen}></AlertDialog> :
                null}

            {alertRemoveOpen ?
                <AlertDialog open={alertRemoveOpen} title="Remove place from list"
                             message="Would you like to remove this place from the list?"
                             handleClickOpen={handleAlertRemoveOpen}></AlertDialog> :
                null}

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    maxWidth: 500,
                    width: '100%',
                }}
            >
                {/*<Box*/}
                {/*    sx={{*/}
                {/*        display: "flex",*/}
                {/*        flexDirection: "row",*/}
                {/*        justifyContent: "space-between",*/}
                {/*        flexWrap: 'nowrap',*/}
                {/*        margin: '5px',*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <FormControl*/}
                {/*        id={"vegas-strip-filter"}*/}
                {/*        sx={{*/}
                {/*            backgroundColor: 'white',*/}
                {/*            borderRadius: '10px',*/}
                {/*            padding: '5px'*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <FormControlLabel*/}

                {/*            control={*/}
                {/*                <Checkbox*/}
                {/*                    checked={onStrip}*/}
                {/*                    onChange={handleCheckChange}*/}
                {/*                />*/}
                {/*            }*/}
                {/*            label="Las Vegas Strip"*/}
                {/*        />*/}
                {/*    </FormControl>*/}
                    <Button variant="contained" sx={{backgroundColor: Constants.appBarColor, padding: '5px', marginTop: '10px',}}
                            id={"list-map"}
                            aria-label="map"
                            component={Link} to={"/amarillo-food-map"}>
                        <MapOutlined/> Map
                    </Button>
                {/*</Box>*/}


                <FormControl sx={{marginTop: '15px', backgroundColor: 'white', borderRadius: '10px', width: '100%'}}
                             fullWidth>
                    <InputLabel sx={{color: 'black', backgroundColor: 'white', borderRadius: '10px', padding: '3px'}}>
                        Category
                    </InputLabel>
                    <Select
                        sx={{
                            borderRadius: '10px',
                        }}
                        inputProps={{
                            MenuProps: {
                                MenuListProps: {
                                    sx: {}
                                }
                            }
                        }}
                        labelId="demo-simple-select-label"
                        id="category-filter"
                        value={selectedCategory}
                        label="Category"
                        onChange={handleChange}
                    >
                        <MenuItem value='All'>All</MenuItem>
                        {categories.map(category => {
                            return (
                                <MenuItem key={category.id} value={category.name}>
                                    {category.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Box>

            {placesToUse.length !== 0 ?

                placesToUse.map((place, index) => (
                    <Card key={place.id} sx={{
                        maxWidth: 500,
                        width: '100%',
                        marginTop: "10px",
                        marginBottom: "10px",
                        display: "flex",
                        flexDirection: "column",
                    }}>
                        <CardContent sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "start",
                            alignItems: "center",
                        }}>
                            <Typography
                                sx={{fontSize: 14, width: '100%', textAlign: 'center'}}
                                color={appBarColor}
                                onClick={() => openPlaceDetails(place)}
                            >
                                <h3 key={place.id + place.description}>{place.name !== undefined ? place.name : ''}</h3>
                            </Typography>
                            {
                                place.logo_url ? <img
                                    width='100px'
                                    height='100px'
                                    src={place.logo_url}
                                    onClick={() => openPlaceDetails(place)}
                                /> : null
                            }
                            {
                                place.rating === '' ? null :
                                    <Typography sx={{fontSize: 13, width: '100%', textAlign: 'center'}}
                                                color={appBarColor} gutterBottom>
                                        <p><strong>Highest Rating: </strong>{place.rating}</p>
                                    </Typography>
                            }
                            <Typography sx={{fontSize: 13, width: '100%', textAlign: 'center'}} color={appBarColor}
                                        gutterBottom>
                                <div
                                    key={place.id + place.description + place.name}>{place.place_category !== undefined ? place.place_category.map((value) => value.category_id.name).join(", ") : ''}</div>
                            </Typography>
                            {
                                categoryContainsFoodTruck(place) ?
                                    <Typography sx={{fontSize: 13, width: '100%',  textAlign: 'center'}} color={appBarColor} gutterBottom>
                                    <strong> Notice: Location varies. Please call and/or visit website before clicking the <i>Let's Go!</i> button.</strong>
                                    </Typography> : null
                            }
                            <Typography sx={{fontSize: 14, fontStyle: 'italic'}} color="black">
                                <p>{place.description}</p>
                            </Typography>
                        </CardContent>
                        <Divider sx={{width: '100%'}}></Divider>
                        <CardActions>
                            <PlaceCardAction index={index} place={place} saveToList={() => saveToList(place)}
                                             isApple={isApple}/>
                        </CardActions>
                    </Card>
                ))
                : <LoadingAnimation/>
            }
        </Box>
    );
}

export default Places;
