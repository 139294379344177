import './App.css';
import React, {useEffect, useState} from "react";
import {useTheme} from '@mui/material/styles';
import {Box, Button, Card, CardActions, CardContent, IconButton, Typography, useMediaQuery} from "@mui/material";
import {appBarColor, backgroundColor} from "./Constants";
import {useDispatch, useSelector} from 'react-redux';
import {DeleteOutlined, MapOutlined} from '@mui/icons-material';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {deletePlaceFromList, getUserPlacesFromList, loadUser} from './redux/reducers/authSlice';
import AlertDialog from './AlertDialog';
import {setCurrentPlace} from './redux/reducers/placesSlice';

const ListPlaces = () => {
    const [data, setData] = useState([]);
    const [alertRemoveOpen, setAlertRemoveOpen] = useState(false);
    const [isApple, setIsApple] = useState(false);

    const theme = useTheme();
    const navigate = useNavigate();
    const matches = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const params = useParams();

    const places = useSelector(state => state.auth.listPlaces);
    const user = useSelector(state => state.auth.user);

    useEffect(() => {
        // Check if the user is on an Apple device
        const isAppleDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);
        setIsApple(isAppleDevice);
        if (places.length === 0) {
            initData()
        }

    }, []);

    const initData = async () => {
        if (user == null) {
            dispatch(loadUser())
        }
        if (places.length === 0) {
            dispatch(getUserPlacesFromList(params.id));
        }

    }

    function watchVideo(place) {
        window.open(`${place.video_id}`, '_blank', 'noreferrer');
    }

    const orderFood = (place) => {
        window.open(place.order_url, '_blank', 'noreferrer');
    }

    const openWebsite = (place) => {
        window.open(place.website_url, '_blank', 'noreferrer');
    }


    const callPlace = (place) => {
        const phone = place.phone;
        const href = `tel:${phone}`;
        window.open(href);
    }


    const emailPlace = (place) => {
        const name = place.name;
        const phone = place.phone;
        const address = place.address;
        const detailUrl = `https://amarillofood.app/place/${params.id}`;
        const href = `mailto:?subject=let's eat at this restaurant&body=Hi, Amarillo reviewed this restaurant! ${detailUrl}`;
        window.open(href);
    }

    function goToMap(place) {
        if (isApple) {
            window.open(`maps://?q=${place.lat},${place.lng}`);
        } else {
            window.open(`https://www.google.com/maps/dir/?api=1&destination=${place.lat},${place.lng}`);
        }
    }

    const handleAlertRemoveOpen = (didAccept) => {
        setAlertRemoveOpen(false)
        if (didAccept) {
            dispatch(deletePlaceFromList(data.id, user.uid, params.id))
        }
    }
    const removePlaceFromList = (place) => {
        setData(place)
        setAlertRemoveOpen(true)
    }

    const openPlaceDetails = (place) => {
        dispatch(setCurrentPlace(place))
        navigate(`/place/${place['slug']}`)
    }

    return (
        <Box
            sx={{
                backgroundColor: backgroundColor,
                maxWidth: '100%',
                overflowX: 'hidden',
                minHeight: '100vh',
                marginRight: matches ? '10px' : '250px',
                marginLeft: matches ? '10px' : '250px',
            }}
        >

            {alertRemoveOpen ?
                <AlertDialog open={alertRemoveOpen} title="Remove place from list"
                             message="Would you like to remove this place from the list?"
                             handleClickOpen={handleAlertRemoveOpen}></AlertDialog> :
                <div></div>}


            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    margin: matches ? "7px" : "15px",
                }}
            >
                <Button variant="contained" sx={{backgroundColor: "#222E50", padding: '5px'}} aria-label="map"
                        component={Link} to={`/listmap/${params.id}`}>
                    <MapOutlined/> Map
                </Button>
            </Box>

            {places.length !== 0 ?

                places.map(place => (
                    // const categories = place.
                    <Card key={place.id} sx={{
                        // margin: "7px",
                        marginRight: matches ? "7px" : "15px",
                        marginLeft: matches ? "7px" : "15px",
                        marginTop: "10px",
                        marginBottom: "30px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        // width: '100%',
                    }}>
                        <CardContent sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>


                            <Typography sx={{fontSize: 14,}} color={appBarColor}>
                                <h3 key={place.id + place.description}>{place.name !== undefined ? place.name : ''}</h3>
                            </Typography>
                            {
                                place.rating === '' ? <div></div> :
                                    <Typography sx={{fontSize: 13,}} color={appBarColor} gutterBottom>
                                        <p><strong>Highest Rating: </strong>{place.rating}</p>
                                    </Typography>
                            }
                            <Typography sx={{fontSize: 13,}} color={appBarColor} gutterBottom>
                                <div
                                    key={place.id + place.description + place.name}>{place.place_category !== undefined ? place.place_category.map((value) => value.category_id.name).join(", ") : ''}</div>
                            </Typography>

                            <Typography sx={{fontSize: 14, fontStyle: 'italic'}} color="black" gutterBottom>
                                <p>{place.description}</p>
                            </Typography>
                            <Typography sx={{fontSize: 14}} color="black" gutterBottom>
                                <p>{place.address !== undefined ? place.address : ''}</p>
                            </Typography>
                            <IconButton onClick={() => removePlaceFromList(place)}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <DeleteOutlined sx={{color: appBarColor,}}/>
                                    <Typography sx={{fontSize: 14, marginLeft: '5px'}} color={appBarColor} gutterBottom>
                                        Remove place from list
                                    </Typography>
                                </Box>
                            </IconButton>
                        </CardContent>
                        <CardActions>
                            <Box
                                sx={{
                                    width: '100%',
                                    maxWidth: '100%',
                                    height: '100%',
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-evenly",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-evenly",

                                    }}
                                >

                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            // justifyContent: "space-around",
                                            justifyContent: "space-evenly",
                                            alignContent: "flex-start",
                                            // justifyContent: "center",
                                            margin: '7px',
                                            flexBasis: 'auto'
                                        }}
                                    >
                                        {
                                            place.lat === '' ?
                                                <div></div> :
                                                <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: appBarColor,
                                                            margin: '10px',
                                                            // width: '25px',
                                                            fontSize: matchesMd ? '0.7em' : '1em'
                                                        }}
                                                        size="small"
                                                        onClick={() => goToMap(place)}>Directions</Button>
                                        }
                                        {
                                            place.phone === '' ?
                                                <div></div> :
                                                <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: appBarColor,
                                                            margin: '10px',
                                                            // width: '25px',
                                                            fontSize: matchesMd ? '0.7em' : '1em'
                                                        }}
                                                        size="small"
                                                        onClick={() => callPlace(place)}>Call</Button>
                                        }
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-evenly",
                                            alignContent: "flex-start",
                                            margin: '7px',
                                            flexBasis: 'auto'
                                        }}
                                    >
                                        <Button variant="contained" size="small"
                                                sx={{
                                                    backgroundColor: appBarColor,
                                                    margin: '10px',
                                                    fontSize: matchesMd ? '0.7em' : '1em'
                                                }}
                                                onClick={() => watchVideo(place)}>
                                            Watch Review
                                        </Button>
                                        {
                                            place.order_url === '' ?
                                                <Box sx={{width: "0px"}}></Box> :
                                                <Button variant="contained" sx={{
                                                    backgroundColor: appBarColor,
                                                    margin: '7px',
                                                    fontSize: matchesMd ? '0.7em' : '1em'
                                                }} size="small"
                                                        onClick={() => orderFood(place)}>Order</Button>
                                        }
                                        {/*<Button variant="contained" size="small"*/}
                                        {/*        sx={{*/}
                                        {/*            backgroundColor: "#222E50",*/}
                                        {/*            margin: '10px',*/}
                                        {/*            fontSize: matchesMd ? '0.7em' : '1em'*/}
                                        {/*        }}*/}
                                        {/*        onClick={() => emailPlace(place)}>*/}
                                        {/*    Share*/}
                                        {/*</Button>*/}
                                    </Box>


                                </Box>

                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-evenly",
                                        alignContent: "flex-start",
                                        margin: '7px',
                                        flexBasis: 'auto'
                                    }}
                                >
                                    {/*{*/}
                                    {/*    place.order_url === '' ?*/}
                                    {/*        <Box sx={{width: "0px"}}></Box> :*/}
                                    {/*        <Button variant="contained" sx={{*/}
                                    {/*            backgroundColor: "#222E50",*/}
                                    {/*            margin: '7px',*/}
                                    {/*            fontSize: matchesMd ? '0.7em' : '1em'*/}
                                    {/*        }} size="small"*/}
                                    {/*                onClick={() => orderFood(place)}>Order</Button>*/}
                                    {/*}*/}
                                    {/*{*/}
                                    {/*    place.website_url === '' ?*/}
                                    {/*        <Box sx={{width: "0px"}}></Box> :*/}
                                    {/*        <Button variant="contained" sx={{*/}
                                    {/*            backgroundColor: "#222E50",*/}
                                    {/*            margin: '7px',*/}
                                    {/*            fontSize: matchesMd ? '0.7em' : '1em'*/}
                                    {/*        }} size="small"*/}
                                    {/*                onClick={() => openWebsite(place)}>Website</Button>*/}
                                    {/*}*/}
                                    {
                                        place.website_url === '' ?
                                            <Box sx={{width: "0px"}}></Box> :
                                            <Button variant="contained" sx={{
                                                backgroundColor: appBarColor,
                                                margin: '7px',
                                                fontSize: matchesMd ? '0.7em' : '1em'
                                            }} size="small"
                                                    onClick={() => openPlaceDetails(place)}>More...</Button>
                                    }
                                </Box>
                            </Box>

                        </CardActions>
                        <hr/>
                    </Card>
                ))
                : <Box sx={{color: 'white'}}>You don't have any places saved. Go to the home page to save some.</Box>
            }
        </Box>
    );
}

export default ListPlaces;
