import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
    Box,
    Button,
    Dialog, DialogActions, DialogContent, DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField
} from '@mui/material';
import {addPlaceToList, createPlaceList, getUserPlacesFromList} from './redux/reducers/authSlice';
import Constant from './Constants';


const AddToFoodListDialog = (props) => {
    const foodList = useSelector(state => state.auth.foodLists);
    const foodListMap = useSelector(state => state.auth.foodListMap);
    const placesMap = useSelector(state => state.auth.listPlacesMap);
    const user = useSelector(state => state.auth.user);
    const dispatch = useDispatch();

    const [selectedFoodListId, setSelectedFoodListId] = useState(null);
    const [selectedFoodList, setSelectedFoodList] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [foodlistPublic, setFoodlistPrivacy] = React.useState(false);
    const [foodlistName, setNewFoodlistName] = React.useState("");

    const handleFoodlistSelectedChange = (event) => {
        const listId = event.target.value;
        setSelectedFoodListId(listId)
        setSelectedFoodList(foodListMap[listId])
    }

    const addToSelectedFoodlist = async () => {

        if (selectedFoodListId !== null) {
            await dispatch(addPlaceToList(props.data.id, user.uid, selectedFoodList))
            setNewFoodlistName('');
        }
        props.handleClickOpen(false)
    }


    useEffect(() => {
    }, [foodList]);


    const handleOpen = () => {
        props.handleClickOpen(false)
    };

    const saveNewFoodList = async () => {
        await dispatch(createPlaceList(foodlistName, user.uid));
        setNewFoodlistName('');
    }

    const handleFoodlistNameChange = (e) => {
        setNewFoodlistName(e.target.value);
    }

    const handleFoodlistPrivacyChange = (e) => {
        setFoodlistPrivacy(e.target.value);
    }

    return (
        <div>
            <Dialog aria-labelledby="customized-dialog-title" open={props.open}>
                <DialogTitle id="customized-dialog-title" onClose={handleOpen}>
                    {<h2>Add <i>'{props.data.name}'</i> to your list</h2>}
                    {/*{<p>{props.data.name} </p>}*/}
                </DialogTitle>
                <DialogContent dividers>
                    {foodList.length === 0 ? <div>
                            <div>
                                <form noValidate autoComplete="off">
                                    <TextField id="listname" name="listname" label="List Name"
                                               onChange={handleFoodlistNameChange}
                                               variant="outlined"/>
                                </form>
                            </div>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '8px'
                            }}>
                                {/*<FormControl component="fieldset">*/}
                                {/*    <FormLabel style={{padding: '5px'}} component="legend">List Visibility:</FormLabel>*/}
                                {/*    <RadioGroup aria-label="gender" name="gender1" value={foodlistPublic}*/}
                                {/*                onChange={handleFoodlistPrivacyChange}*/}
                                {/*                row>*/}
                                {/*        <FormControlLabel value={false} control={<Radio/>} label="Private"/>*/}
                                {/*        <FormControlLabel value={true} control={<Radio/>} label="Public"/>*/}
                                {/*    </RadioGroup>*/}
                                {/*</FormControl>*/}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={saveNewFoodList}
                                    sx={{backgroundColor: Constant.appBarColor}}
                                >
                                    Create List
                                </Button>
                            </Box>
                        </div> :
                        <Box sx={{color: Constant.appBarColor}}>We only allow creating one list at the moment.</Box>}
                </DialogContent>
                <DialogContent dividers>
                    <FormControl component="fieldset">
                        {foodList.length !== 0 ?
                            <FormLabel component="legend">Select List </FormLabel> :
                            <FormLabel component="legend">No lists found. Please create one </FormLabel>}

                        <RadioGroup aria-label="foodlists" name="foodlists" value={selectedFoodListId}
                                    onChange={handleFoodlistSelectedChange}>
                            {foodList.length !== 0 ? foodList.map((list, index) => (
                                <FormControlLabel value={list.id} key={list.id}
                                                  control={<Radio/>}
                                                  label={list.title}/>
                                // hasPlace(place) ? <div>Already in list</div> : <div></div>
                            )) : <div></div>}
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button sx={{color: Constant.appBarColor}} onClick={handleOpen} color="primary">
                        Cancel
                    </Button>
                    <Button sx={{color: Constant.appBarColor}} onClick={addToSelectedFoodlist} autoFocus
                            color="primary">
                        Add Place to List
                    </Button>
                </DialogActions>
            </Dialog>
        </div>);
}

export default AddToFoodListDialog;
