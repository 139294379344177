module.exports = {
    // appBarColor: '#222E50',
    appBarColor: '#000000',
    // appBarColor: '#8C0443',
    // cardColor: '#BCD8C1',
    cardColor: '#f8ee26',
    // cardColor: '#000000',
    whiteTextColor: '#FFFFFF',
    // backgroundColor: '#007991',
    backgroundColor: '#B18F9F',
    appName: 'Amarillo Food',
    // backgroundColor: '#BCD8C1',
}
