import React from 'react'
import {Route, Routes} from 'react-router'
import Places from './Places';
import FoodMap from './FoodMap';
import About from './About';
import ListMap from './ListMap';
import ListPlaces from './ListPlaces';
import Place from './Place';
import FoodTour from './FoodTour';

const FoodRouter = () => {
    return (
        <Routes>
            <Route exact path='/' element={<Places/>}/>
            <Route exact path='/amarillo-food-list' element={<Places/>}/>
            <Route exact path='/about-amarillo-food-app' element={<About/>}/>
            <Route exact path='/about' element={<About/>}/>
            <Route exact path="/place/:id" element={<Place/>} />
            <Route exact path="/listmap/:id" element={<ListMap/>} />
            <Route exact path="/listplaces/:id" element={<ListPlaces/>} />
            <Route exact path='/amarillo-food-map' element={<FoodMap/>}/>
            <Route exact path='/amarillo-food-tour' element={<FoodTour/>}/>
            <Route exact path='/foodmap' element={<FoodMap/>}/>
        </Routes>
    )
}

export default FoodRouter
