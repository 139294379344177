import {Box, Card, CardActionArea, CardContent, Typography} from '@mui/material';
import Divider from '@mui/material/Divider';
import SEO from './SEO';
import React, {useEffect} from 'react';

const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <Card sx={{maxWidth: '100%'}}>
        <SEO
            title={`About the Amarillo food app`}
            description={`A list of food places in Amarillo, Tx.`}
            name={`About the Amarillo Food app`}
            type={`article`}/>
        <CardActionArea>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div" sx={{padding: '5px'}}>
                    About
                < /Typography>
                <Typography variant="body2" color="text.secondary">
                    <p>Find Amarillo Food from locally owned restaurants and food trucks.</p>
                </Typography>
                <Divider></Divider>
                <Typography variant="h5" color="text.secondary" sx={{padding: '5px'}}>
                    <div>Install the Amarillo food app</div>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    <p>Since this is a PWA (progressive web app), you can add an app icon to your phone. Similar to a native
                        app that you download from the App and Play store</p>
                    <p>To install the Amarillo food app on iOS, click <strong>Share</strong>, <strong>Add To Home
                        Screen</strong></p>
                    <p>To install the Amarillo food on Android, click <strong>Settings(3 dots)</strong>, <strong>Install
                        App</strong></p>
                </Typography>
                <Divider></Divider>

                <br/>
                <Typography variant="h5" color="text.secondary" sx={{padding: '5px'}}>
                    <div>Feature Requests and Bug Reporting</div>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    <p>Use the following form to request new features and/or issues.</p>
                    <a href="https://forms.gle/2fBvK6HsCtBCPxtw8" target={'_blank'}>
                      Feature Requests/Bug Reporting form
                    </a>
                </Typography>
            </CardContent>
        </CardActionArea>
    </Card>

}

export default About;
