import React, {useEffect, useState} from 'react';
import {Box, Button, useMediaQuery} from "@mui/material";
import {useDispatch, useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import {MapOutlined} from '@mui/icons-material';
import {useTheme} from '@mui/material/styles';
import {getUserPlacesFromList} from './redux/reducers/authSlice';

const ListMap = () => {

    const [markers, setMarkers] = useState([]);
    const [map, setMap] = useState(null);
    const dispatch = useDispatch();
    const params = useParams();
    const categories = useSelector(state => state.categories.categories);
    const places = useSelector(state => state.auth.listPlaces);
    let google;

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('lg'));


    useEffect(() => {
        if (places.length === 0) {
            initData()
        }

        if (map === null) {
            setupMap()
        }

        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (map != null && places.length > 0 && markers.length === 0) {
            initMapData()
        }

    }, [places, map]);

    const initMapData = async () => {
        await setupMarkers()
    }

    const setupMap = async () => {
        google = window.google
        const {Map} = await google.maps.importLibrary("maps");

        setMap(new Map(document.getElementById("map"), {
                center: {lat: 36.088110, lng: -115.176468},
                zoom: 10.9,
            })
        )
    }

    const initData = async () => {
        await dispatch(getUserPlacesFromList(params.id));
    }

    const clearMarkers = async () => {
        markers.forEach((marker) => {
            marker.setMap(null)
            // marker = null
        })
        setMarkers([])
    }

    const setupMarkers = async () => {
        await clearMarkers()

        const isAppleDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);

        google = window.google
        let currentMarkers = [];
        places.forEach((place) => {
            const position = {lat: +place.lat, lng: +place.lng};

            const marker = new google.maps.Marker({
                map: map,
                position: position,
                title: place.name,
                color: 'black',
                label: place.rating === '' ? null : {
                    color: '#ffffff',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    text: place.rating
                },
                // label: { color: '#00aaff', fontWeight: 'bold', fontSize: '14px', text: 'Your text here' },
            });


            let directionsLink = `https://www.google.com/maps/dir/?api=1&destination=${place.lat},${place.lng}`;
            if (isAppleDevice) {
                directionsLink = `maps://?q=${place.lat},${place.lng}`;
            }

            const phone = place.phone;
            const callHref = `tel:${phone}`;

            const name = place.name;
            const address = place.address;
            const tiktokUrl = `${place.video_id}`;
            const detailUrl = `https://amarillofood.app/place/${params.id}`;
            const shareHref = `mailto:?subject=let's eat at this restaurant&body=Hi, Amarillo reviewed this restaurant! ${detailUrl}`;

            let categories = `<div></div>`;
            if (place.place_category)
                categories = `<i>${place.place_category.map((value) => value.category_id.name).join(", ")}</i>`;

            let callDiv = `<div></div>`
            if (place.phone !== '') {
                callDiv = `<hr>
         <div>
                 <a href="${callHref}" target="_blank"/>Call</a>
        </div>`;
            }

            let orderUrl = `<div></div>`
            if (place.order_url !== '') {
                orderUrl = `<hr>
         <div>
                <a href="${place.order_url}" target="_blank"/>Order</a>
        </div>`;
            }

            let websiteUrl = `<div></div>`
            if (place.website_url !== '') {
                websiteUrl = `<hr>
         <div>
                <a href="${place.website_url}" target="_blank"/>Website</a>
        </div>`;
            }


            let dir = `<div></div>`
            if (place.lat !== '') {
                dir = `<hr>
         <div>
                <a href="${directionsLink}" target="_blank"/>Directions</a>
        </div>`;
            }
            const information = new google.maps.InfoWindow({
                content: `
                         <h4>${place.name}</h4>
                             ${categories}
                         <hr>
                         <div>
                             ${address}
                         </div>
                         ${dir}
                         ${callDiv}
                         <hr>
                         <div>
                             <a href="${shareHref}" target="_blank"/>Share</a>
                         </div>
                         <hr>
                          <div>
                             <a href="${tiktokUrl}" target="_blank"/>Watch Review</a>
                         </div>
                         ${orderUrl}
                         ${websiteUrl}
                         `
            });

            marker.addListener('click', function () {
                information.open(map, marker);
            });
            currentMarkers.push(marker)
        });

        setMarkers(currentMarkers)

    }


    return <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            justifyContent: 'space-between'
            // width: '100%'
        }}

    >
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginRight: matches ? '10px' : '250px',
                marginLeft: matches ? '10px' : '250px',
                marginTop: "7px",
                marginBottom: "7px"
            }}
        >
            <Button
                variant="contained"
                sx={{
                    backgroundColor: "#222E50",
                    padding: '5px',
                }}
                aria-label="list"
                component={Link}
                to={`/listplaces/${params.id}`}
            >
                <MapOutlined/> List
            </Button>
        </Box>


        {places.length !== 0 ? <div></div> :
            <Box sx={{color: 'white', padding: '5px'}}>You don't have any places saved. Go to the home page to save
                some.</Box>}

        <Box
            sx={{
                // display: 'flex',
                // flexDirection: 'column',
                height: '100%',
                // justifyContent: 'space-between'
                width: '100%'
            }}

            id="map"
        >

        </Box>

    </Box>


}

export default ListMap;
