import {createSlice} from '@reduxjs/toolkit'
import {supabase} from '../../supabaseClient';

const initialState = {
    places: [],
    filteredPlaces: [],
    placesMap: {},
    currentPlace: null,
    currentPlaceFood: [],
    placesToUse: [],
    placesOnTheStrip: [],
    placesOnTour: [],
    generatedPlaces: [],
    onStrip: false,
}

const placesSlice = createSlice({
    name: 'places',
    initialState,
    reducers: {
        setPlaces(state, action) {
            state.places = action.payload;
            state.placesToUse = action.payload;
            state.placesOnTour = action.payload;

            // state.placesMap = action.payload.reduce((acc, place) => {
            //     acc[place.id] = place;
            //     return acc;
            // }, {})

        },
        setCurrentPlace(state, action) {
            state.currentPlace = action.payload;
        },
        setPlacesOnStrip(state, action) {
            state.placesOnTheStrip = action.payload;
        },
        setOnStrip(state, action) {
            state.onStrip = action.payload;
        },
        setCurrentFood(state, action) {
            state.currentPlaceFood = action.payload;
        },
        setFilteredPlaces(state, action) {
            state.filteredPlaces = action.payload;
            state.placesToUse = action.payload;
        },
        setPlacesOnTour(state, action) {
            state.placesOnTour = action.payload;
        },
        setGeneratedPlaces(state, action) {
            state.generatedPlaces = action.payload;
        },
        resetPlaces(state, action) {
            state.placesToUse = state.places;
        },
        resetPlacesOnTour(state, action) {
            state.placesOnTour = state.places;
        },
    }
})

export const {
    setPlaces,
    setFilteredPlaces,
    resetPlaces,
    resetPlacesOnTour,
    setCurrentPlace,
    setCurrentFood,
    setPlacesOnStrip,
    setPlacesOnTour,
    setGeneratedPlaces,
    setOnStrip
} = placesSlice.actions

export const getPlaces = () => async dispatch => {
    const {data, error} = await supabase.from('places')
        .select(
            `
            id,
            address,
            description,
            lat,
            lng,
            phone,
            video_id,
            order_url,
            name,
            rating,
            website_url,
            logo_url,
            google_place_id,
            slug,
            place_category ( id, category_id(name) )
       `
        )
        .order('name')

    if (!error)
        await dispatch(setPlaces(data))
}

export const getCurrentPlace = (slug) => async dispatch => {
    const {data, error} = await supabase.from('places')
        .select(
            `
            id,
            address,
            description,
            lat,
            lng,
            phone,
            video_id,
            order_url,
            name,
            rating,
            website_url,
            logo_url,
            slug,
            google_place_id,
            place_category ( id, category_id(name) )
       `
        )
        .eq('slug', slug)
    await dispatch(setCurrentPlace(data[0]))
}

export const getPlacesOnTheStrip = () => async dispatch => {
    const {data, error} = await supabase.from('places')
        .select(
            `
            id,
            address,
            description,
            lat,
            lng,
            phone,
            video_id,
            order_url,
            name,
            rating,
            website_url,
            logo_url,
            slug,
            google_place_id,
            place_category ( id, category_id(name) )
       `
        )
        .eq('on_vegas_strip', true)
    await dispatch(setPlacesOnStrip(data))
}

export const getFoodFromPlace = (placeId) => async dispatch => {
    const {data, error} = await supabase.from('food')
        .select()
        .eq('place_id', placeId)
    await dispatch(setCurrentFood(data))
}


export const updateFilteredCategories = (category, places, onVegasStrip) => async dispatch => {

    const filteredPlaces = await places.filter((place) => {
        if (place.place_category.length === 0) return false
        for (let i = 0; i < place.place_category.length; i++) {
            const placeCategory = place.place_category[i];
            if (placeCategory.category_id.name.toLowerCase() === category.toLowerCase() || category.toLowerCase() === 'all') {
                return true;
            }
        }
        return false
    })

    // filter out the places that do have the category using placesObject
    // const filteredPlaces = await places.filter((place) => {
    //     if (placesObject[place.id]) return true
    //     return false
    // })

    await dispatch(setFilteredPlaces(filteredPlaces))
}

export const resetPlacesToUse = () => async dispatch => {
    await dispatch(resetPlaces())
}
export const resetTour = () => async dispatch => {
    await dispatch(resetPlacesOnTour())
}

export default placesSlice.reducer
