import {Box, IconButton, Menu, MenuItem, Typography} from '@mui/material';
import {Call, Directions, Fastfood, MoreHorizRounded, SaveOutlined, Tv, Web} from '@mui/icons-material';
import {appBarColor} from './Constants';
import {setCurrentPlace} from './redux/reducers/placesSlice';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import PlaceCardActionItem from './PlaceCardActionItem';

const PlaceCardAction = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const place = props.place;

    const [isApple, setIsApple] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        // Check if the user is on an Apple device
        setIsApple(props.isApple);
    }, []);


    const openPlaceDetails = () => {
        dispatch(setCurrentPlace(place))
        navigate(`/place/${place['slug']}`)
    }

    function watchVideo() {
        console.log(place.video_id)
        window.open(`${place.video_id}`, '_blank', 'noreferrer');
    }

    const orderFood = () => {
        window.open(place.order_url, '_blank', 'noreferrer');
    }

    const openWebsite = () => {
        window.open(place.website_url, '_blank', 'noreferrer');
    }

    const callPlace = () => {
        const phone = place.phone;
        const href = `tel:${phone}`;
        window.open(href);
    }

    const goToMap = () => {
        if (isApple) {
            window.open(`maps://?q=${place.lat},${place.lng}`);
        } else {
            window.open(`https://www.google.com/maps/dir/?api=1&destination=${place.lat},${place.lng}`);
        }
    }

    return <Box
        sx={{
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
        }}
    >

        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
            }}
        >

            {/*<PlaceCardActionItem id={props.index === 0 ? 'save-step' : `save${props.index}`} icon={<SaveOutlined sx={{color: appBarColor,}}/>} title={'Save'}*/}
            {/*                     action={() => props.saveToList(place)}/>*/}



            {
                place.lat !== '' ?
                    <PlaceCardActionItem id={props.index === 0 ? 'directions-step' : `save${props.index}`}
                                         icon={<Directions sx={{color: appBarColor,}}/>} title={'Let\'s Go!'}
                                         action={() => goToMap()}/> : null
            }

            {
                place.phone !== '' && (place.video_id === null || place.video_id === '') ?
                    <PlaceCardActionItem id={props.index === 0 ? 'call-step' : `save${props.index}`}
                                         icon={<Call sx={{color: appBarColor,}}/>} title={'Call'}
                                         action={() => callPlace()}/> : null
            }


            {place['website_url'] ?
                <PlaceCardActionItem id={props.index === 0 ? 'website-step' : `save${props.index}`} icon={<Web sx={{color: appBarColor,}}/>} title={'Website'}
                                     action={() => openWebsite()}/> : null}


            {
                place.video_id !== null && place.video_id !== '' ?
                    <PlaceCardActionItem id={props.index === 0 ? 'watch-step' : `save${props.index}`}
                                         icon={<Tv sx={{color: appBarColor,}}/>} title={'Watch Review'}
                                         action={() => watchVideo()}/>  : null
            }

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
                id={props.index === 0 ? 'more-step' : `save${props.index}`}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <IconButton>
                    <MoreHorizRounded sx={{color: appBarColor,}}/>
                </IconButton>
                <Typography sx={{fontSize: 13}} color={appBarColor}>
                    More
                </Typography>
            </Box>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'more-step',
                }}
            >
                <MenuItem onClick={() => openPlaceDetails()}>Profile</MenuItem>
                {place['order_url'] ? <MenuItem onClick={() => orderFood()}>Order</MenuItem> : null}
                {place['website'] ? <MenuItem onClick={() => openWebsite()}>Website</MenuItem> : null}
                <MenuItem onClick={() => callPlace()}>Call</MenuItem>
            </Menu>
        </Box>
    </Box>
}

export default PlaceCardAction;
